import NavBar from "./componentes/NavBar";
import image from  './galaxia.jpg';
import nubes from './nubes.jpg'

function App() {
  return (
    <div className="App"  /* style={{backgroundImage:`url(${nubes}`}} */ > 
    <NavBar/>
    
    </div>
  );
}

export default App;
